<template>
<div>
  <div class="mx-3 mt-6">
    <Card class="mt-6 p-5">
      <template v-slot:footer>
        <card-footer
          reloadcard
          @reload="reload()"
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
        />
      </template>
    </Card>
  </div>
  <div class="w-full">
    <div class="w-full h-full p-3 mb-8">
      <sTable
        :headers="employeeHeader"
        :items="employees"
        style="width:100%"
        :loading="loading"
        aria-label="employee table"
        class="w-full"
        id="printMe"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
        has-number
      >
        <template v-slot:item="{ item }">
          <template v-if="item.fname">
            <div class="flex flex-row items-center" @click="viewEmployeeDetails(item.data)">
              <template>
                <div class="flex w-2 h-2 rounded mr-2">
                    <span class="p-1 rounded"
                          :class="item.data.userInfo.employmentStatus === 'confirmed' ? 'bg-mediumSeaGreen':
                      item.data.userInfo.employmentStatus === 'suspended' ? 'bg-carrotOrange':
                      item.data.userInfo.employmentStatus === 'disengaged' ? 'bg-flame' :
                      item.data.userInfo.employmentStatus === 're-engaged' ? 'bg-mediumSeaGreen' : 'bg-romanSilver'"
                    />
                </div>
              </template>
              <template>
                <div>
                  <img v-if="item.data.photo" :src="item.data.photo" alt="profile pic" class="w-8 h-8 rounded" />
                  <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                </div>
              </template>
              <div class="ml-2 text-sm flex flex-col justify-Start">
                  <span class="font-bold cursor-pointer text-jet">
                    {{ item.data.fname }} {{ item.data.lname }}
                  </span>
                <!-- <div class="text-romanSilver text-xs uppercase font-bold">
                  <span v-if="item.data.userDesignation">
                    {{ item.data.userDesignation.name }}
                  </span>
                  <span v-else>-</span>
                </div> -->
              </div>
            </div>
          </template>
          <span v-else-if="item.orgFunction" class="text-center">
              {{ !item.data.orgFunction ? "-" : item.data.orgFunction.name }}
            </span>
          <span v-else-if="item.userDesignation" class="text-center">
              {{
              item.data.userDesignation ? item.data.userDesignation.name : "-"
            }}
            </span>
          <span v-else-if="item.userInfo" class="text-center">
              {{ item.data.userInfo.employmentType || "-" }}
            </span>
          <div v-else-if="item.userReportingTo" class="">
              <span v-if="item.data.userReportingTo">
                {{ item.data.userReportingTo.fname }} {{ item.data.userReportingTo.lname }}
              </span>
            <span v-else>-</span>
          </div>
          <span v-else-if="item.suspension" class="text-center">
              {{$DATEFORMAT(new Date(item.data.suspension.startDate), "MMMM dd, yyyy")}}
            </span>
          <span v-else-if="item.suspend" class="mr-4 text-center capitalize" :class="item.data.userInfo.employmentStatus === 'suspended' ? 'suspended' : 'recalled'"  >
              {{ item.data.userInfo ? item.data.userInfo.employmentStatus : '-' }}
            </span>
          <span v-else-if="item.email">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                    <div class="w-full" @click="viewEmployeeDetails(item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">View</p>
                      </div>
                    </div>
                    <div class="w-full" @click="viewApprovalStatus(item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="icon-upgrade" class-name="text-mediumSeaGreen" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Approval Status</p>
                      </div>
                    </div>
                  </div>
                </Menu>
              </span>
        </template>
      </sTable>
    </div>
  </div>
  <ApprovalStatus
    v-if="openApprovalStatusModal"
    :open-modal="openApprovalStatusModal"
    @close="handleCloseApprovalStatusModal"
    :user-data="approvalStatusData"
    title="Suspension Approval Status"
  />

</div>
</template>

<script>
import {mapState} from "vuex";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import ApprovalStatus from "@/components/direct-report/approvalstatus"

export default {
  name:"MssSuspendedEmployees",
  components: {STable, CardFooter, Card, Menu,ApprovalStatus},
  data() {
    return {
      functions: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      sortType: null,
      functionIds: [],
      designationIds: [],
      userDetails: {},
      openTransferEmployeeModal: false,
      openUpdateEmployeeModal: false,
      openSuspendModal: false,
      openPromoteModal: false,
      openReviseModal: false,
      openDisengageModal: false,
      openApprovalStatusModal: false,
      approvalStatusData : {},
      moreOptions: [
        {
          title: "View",
          icon: "edit",
          color: "blueCrayola",
          slug: "view"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      loading: state => state.employee.loading,
        employees: state => state.employee.employees,
      metaData: state => state.employee.metaData
    }),
    employeeHeader() {
      return [
        { title: "Employee", value: "fname" },
        { title: "FUNCTION", value: "orgFunction" },
        { title: "DESIGNATION", value: "userDesignation" },
        { title: "Employment type", value: "userInfo" },
        { title: "Reporting To", value: "userReportingTo" },
        { title: "Effective Date", value: "suspension" },
        { title: "Status", value: "suspend" },
        { title: "", value: "email", image: true }
      ];
    }
  },
  watch: {},
  methods: {
    queryUpdate() {
      const isActive = this.isActive ? `&isActive=${this.isActive}` : "";
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.$store.dispatch(
        "employee/getReportingSuspendedEmployees",
        `${isActive}${sort}${search}${pageNumber}${itemPage}`
      );
    },
    checkIfPresent(item, itemArray) {
      const itemIndex = itemArray.indexOf(item);
      if (itemIndex >= 0) {
        itemArray.splice(itemIndex, 1);
      } else {
        itemArray.push(item);
      }
      return itemArray;
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    viewApprovalStatus(item) {
      this.approvalStatusData = item;
      this.openApprovalStatusModal = true;
    },
    handleCloseApprovalStatusModal() {
      this.openApprovalStatusModal = false;
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },

    viewEmployeeDetails(details) {
      this.$router.push({
        name: "EssDirectioryDetails",
        params: {
          id: details.userId ? details.userId : details.userInfo.userId,
          backTo: {
            name: 'Direct Report',
            href: "ManagerDirectReporting"
          }
        },
        query: {
          user: `${details.fname} ${details.lname}`,
          gender: details.gender
        }
      });
    },
    reload() {
      this.$store.dispatch("employee/getReportingSuspendedEmployees", '');
    },
    getEmployeeDetail() {
      this.$store.dispatch("employee/getReportingSuspendedEmployees", "");
    }
  },
  async mounted() {
   await this.getEmployeeDetail();
  }
}
</script>


<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.recalled {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.suspended {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

</style>
