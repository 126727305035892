<template>
  <div class="h-full w-full pt-5">
    <h1 class="text-xl text-left font-extrabold pl-4">
      Directory
    </h1>
    <div class="ml-4 mt-4">
      <Tab
        :tabs="tabOptions"
        border
        :active-tab="currentTab"
        @currentTab="currentTab = $event"
      />
    </div>
    <mss-all-employees v-if="currentTab === 'All Employees'"/>
    <mss-suspended-employees v-if="currentTab === 'Suspended Employees'"/>
    <mss-disengaged-employees  v-if="currentTab === 'Disengaged Employees'"/>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import MssAllEmployees from "@/modules/Ess/ManagerSelfService/DirectReporting/AllEmployees";
import MssSuspendedEmployees from "@/modules/Ess/ManagerSelfService/DirectReporting/SuspendedEmployees";
import MssDisengagedEmployees from "@/modules/Ess/ManagerSelfService/DirectReporting/DisengagedEmployees";

export default {
  name: "EssDirectory",
  components: {
    MssDisengagedEmployees,
    MssSuspendedEmployees,
    MssAllEmployees,
    Tab,
  },
  data() {
    return {
      tabOptions: [
        "All Employees",
        "Suspended Employees",
        "Disengaged Employees",
      ],
      currentTab: this.$route.query.status || "All Employees"
    };
  },
};
</script>

<style></style>
